import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/Layout";
import StandardBanner from "../../../components/StandardHero/StandardHero";
import VideoFeaturePanel from "../../../components/VideoFeaturePanel/VideoFeaturePanel";
import Accordion from "../../../components/Accordion/Accordion";
import { Button } from "../../../components/Button/Button";
import parse from "html-react-parser";
const styles = require("./index.module.scss");

const Careers = ({
  data: {
    allVacancies: { nodes: vacancies },

    strapiCareers,
    strapiCareers: { featurePanel, metaData },
  },
}) => {
  const hero = {
    image: strapiCareers.hero.image.localFile.childImageSharp.gatsbyImageData,
    showBreadcrumb: strapiCareers.hero.showBreadcrumb,
    title: "",
    breadcrumbs: [{ name: "CAREERS" }],
  };

  const getStartPosition = () => {
    let startPosition = 0;

    if (window?.location?.hash) {
      const hashtag = window.location.hash;
      const jobNumber = hashtag.substring(
        hashtag.lastIndexOf("Vacancies_") + 10
      );
      const rowLocation = `[data-job='Vacancies_${jobNumber}']`;
      const row = document.querySelector(rowLocation);
      row.click();
    }
    return startPosition;
  };

  useEffect(() => {
    getStartPosition();
  }, []);

  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
        image: hero.image,
      }}
    >
      <StandardBanner
        image={hero.image}
        title={hero.title}
        showBreadcrumb={hero.showBreadcrumb}
        breadcrumbs={hero.breadcrumbs}
      />
      <VideoFeaturePanel data={featurePanel} />

      <Accordion
        transitionTime={300}
        triggerClassName={styles.headerClosed}
        triggerOpenedClassName={styles.headerOpen}
        classParentString={styles.accordionSection}
        triggerOpenedClassName={styles.headerOpen}
        triggerTagName="div"
        openedClassName={styles.accordionOpen}
        closeable={true}
        startPosition={1000}
        careersPage={true}
      >
        {vacancies?.map((job, index) => {
          const hashUrl = `#${job.jobTitle}-${job.id}`;
          const header = (
            <div
              id={`${job.jobTitle}-${job.id}`}
              data-job={job.id}
              className={styles.jobHeader}
              data-index={index}
              onClick={() => {
                document.location.hash = hashUrl;
              }}
            >
              <div>
                <h4> {job.jobTitle} </h4>
              </div>
              <div> {job?.job_type?.type}</div>
              <div> {job.location}</div>
            </div>
          );
          return (
            <div data-trigger={header} key={"info-" + job.id}>
              <div className={styles.jobContent}>
                <div className={styles.copyWrap}>{parse(job.description)}</div>
                <div className={styles.application}>
                  <p> {job.applicationCopy} </p>
                  <Button
                    bkgColor="blackOnLight"
                    label={job.cta.text}
                    type="externalLink"
                    toPage={job.cta.link}
                  ></Button>
                </div>
              </div>
            </div>
          );
        })}
      </Accordion>
    </Layout>
  );
};

export const query = graphql`
  {
    allVacancies: allStrapiVacancies {
      nodes {
        id
        cta {
          link
          text
        }
        description
        jobTitle
        job_type {
          type
        }
        location
        applicationCopy
      }
    }

    strapiCareers {
      hero {
        showBreadcrumb
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
      metaData {
        pageTitle
        metadescription
      }

      featurePanel {
        copy
        heading
        headerImage {
          localFile {
            publicURL
          }
        }

        video {
          video {
            localFile {
              publicURL
            }
          }
          transcript {
            localFile {
              id
              publicURL
            }
          }
        }
        mainImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default Careers;
